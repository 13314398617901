body {
  background-color: #ebeff6 !important;
  background-image: url(/assets/BG.jpg) !important;
}

/*timepicker style*/
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
  width: 100px;
}

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

/*home page styles*/

.masthead {
  display: flex;
  align-items: center;
  background-image: url("/assets/user.png");
  /* background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important; */
  height: 100vh;
}

/* .masthead.segment {
  min-height: 700px;
  padding: 1em 0 !important;
} */

.ui.container {
  /* width: auto; */
  width: 96vw;
  margin-left: 2% !important;
  margin-right: 2% !important;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.masthead h1.ui.header {
  font-size: 4em;
  font-weight: normal;
}

.masthead h2 {
  font-size: 1.7em;
  font-weight: normal;
}

/* .footer.segment {
  padding: 5em 0;
} */

.secondary.inverted.pointing.menu {
  border: none;
}

/*end home page styles*/

/* navbar styles */

.ui.menu .item img.logo {
  margin-right: 1.5em;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg,
    rgb(24, 42, 115) 0%,
    rgb(33, 138, 174) 69%,
    rgb(32, 167, 172) 89%
  ) !important;
}

.ui.main.container,
.main.segment {
  margin-top: 3em;
  margin-left: 3em;
}

.ui.top.container {
  background-color: #fff;
  margin-left: 0% !important;
  margin-right: 0% !important;
  padding-left: 5% !important;
  padding-right: 5% !important;
  width: 100vw;
  -webkit-box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.11);
  box-shadow: 0px 5px 2px 0px rgba(0, 0, 0, 0.11);
}

.ui.topPage.container {
  /* background-color: #fff; */
  margin-left: 0% !important;
  margin-right: 0% !important;
  padding-left: 4% !important;
  padding-right: 4% !important;
}

.ui.center.aligned.segment.attendance-preview {
  background-color: #f5f5f5;
}

.masthead .ui.menu .ui.button,
.ui.menu a.ui.inverted.button {
  margin-left: 0.5em;
}

.ui.menu .item > img:not(.ui) {
  margin-right: 1.5em !important;
}

.ui.menu:not(.vertical) .item > .button {
  margin-left: 0.5em;
}

/*chat comments*/

.ui.comments .comment .comments {
  padding-bottom: 0 !important;
  padding-left: 2em !important;
}

/* dropzone styles */
.dropzone {
  border: dashed 3px #eee;
  border-radius: 5px;
  padding-top: 30px;
  text-align: center;
  /* min-height: 236px; */
}

.dropzone--isActive {
  border: dashed 3px green;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
}
.video-container .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.innerSegment {
  background-color: #f3f3f3 !important;
  padding: 2em 2em !important;
}

.innerSegmentSingle {
  background-color: #f3f3f3 !important;
  padding: 2em 2em !important;
  margin: 1em 3em 3em 3em !important;
}

.mainContainer .segment {
  margin: 3em;
}

.video-container {
  border: 4px solid #ebeff6;
}

.enquire-button {
  background-color: #fff !important;
  border: 1px solid #233787 !important;
  color: #233787 !important;
  margin-left: 10px !important;
}

.enquire-button:hover {
  background-color: #233787 !important;
  border: 1px solid #233787 !important;
  color: #fff !important;
}

.create-button {
  background-color: #233787 !important;
  color: #fff !important;
  border: 1px solid #233787 !important;
  margin-left: 10px !important;
}

.create-button:hover {
  background-color: #fff !important;
  color: #233787 !important;
  border: 1px solid #233787 !important;
}
